import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { Link as MuiLink } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useSelector } from "react-redux";
import { AppState } from "../store/reducers/rootReducer";

import { ssoLoginADRequest } from "services/ssoAuthConfig"; //LoginADRequest
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { authService } from "services/authService";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import "./footer.css"
import configParams from "../../configParams";
import { browsersLanugage } from "helpers/localization";
function Footer() {
  //TODO get data from user and company

  const isUserAuthenticated = authService.isAuthenticated();

  const isAdminOrEmployee =
    authService.isAuthenticated() &&
    (authService.getRole() === "CompanyAdministrator" ||
      authService.getRole() === "CompanyEmployee");

  const userNotAdmin = !authService.isEhiAdmin();
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);

  const companyLinks = companyInfo?.companyLinks;

  const hasCompanyLinks =
    companyLinks && companyLinks?.length > 0 ? true : false;

  const userNotAdminAndHasCompanyLink = hasCompanyLinks && userNotAdmin;

  let apiAddress = window.location.hostname.endsWith("etd.enterprise.co.uk")
  ? configParams.API_BASE_URL
  : configParams.API_BASE_URL_EHI;  
  const baseURL = (window.location.hostname.indexOf("-ne")>0 || window.location.hostname.indexOf("-we")>0
                  ? apiAddress
                  : apiAddress.replace('prd-we-','').replace('prd-ne-','').replace('-we','').replace('-ne',''));
  const userGuideURL = baseURL + "/userGuide";

  const { instance } = useMsal();
  const { t } = useTranslation("translation");

  const privacySupportedLanguages = ['en-GB', 'en-US', 'en-CA', 'fr-FR', 'fr-FR', 'es-ES'];

  const languageCode = isUserAuthenticated ? authService.getUserLocale() : browsersLanugage();


  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));

  const privacyPolicyLink = () => {
    // missing en-ca
    if (privacySupportedLanguages.includes(languageCode)) {
    }
    if (languageCode === "de-DE") {
      return "http://privacy.ehi.com/de-de/home/privacy-policy.html";
    } else if (languageCode === "fr-FR") {
      return "http://privacy.ehi.com/fr-fr/home/privacy-policy.html";
    } else if (languageCode === "fr-CA") {
      return "http://privacy.ehi.com/fr-ca/home/privacy-policy.html";
    } else if (languageCode === "en-CA") {
      return "http://privacy.ehi.com/en-us/home/privacy-policy.html";
    } else if (languageCode === "es-ES") {
      return "http://privacy.ehi.com/es-es/home/privacy-policy.html";
    } else if (languageCode === "en-US") {
      return "http://privacy.ehi.com/en-us/home/privacy-policy.html";
    } else {
      return "http://privacy.ehi.com/en-gb/home/privacy-policy.html";
    }
  };

  const cookiePolicyLink = () => {
    if (languageCode === "de-DE") {
      return "http://privacy.ehi.com/de-de/home/cookie-policy.html";
    } else if (languageCode === "fr-FR") {
      return "http://privacy.ehi.com/fr-fr/home/cookie-policy.html";
    } else if (languageCode === "fr-CA") {
      return "http://privacy.ehi.com/fr-ca/home/cookie-policy.html";
    } else if (languageCode === "en-CA") {
      return "http://privacy.ehi.com/en-us/home/cookie-policy.html";
    } else if (languageCode === "es-ES") {
      return "http://privacy.ehi.com/es-es/home/cookie-policy.html";
    } else if (languageCode === "en-US") {
      return "http://privacy.ehi.com/en-us/home/cookie-policy.html";
    } else return "http://privacy.ehi.com/en-gb/home/cookie-policy.html";
  };
  const styles = {
    backgroundColor: "#006639",
    height: "5px",
    borderWidth: 0,
    marginLeft: "0 em",
    marginRight: "0 em",
    borderCorner: "1em",
    color: "inherit"
  } as React.CSSProperties;

  const handleLogin = () => {
    instance.loginRedirect(ssoLoginADRequest).catch((err) => {
      console.log("Error in logging in: ", err);
      appInsights.trackException({
        properties: {
          method: "Footer: handleLogin",
          Component: "Login",
        },
        exception: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  };

  return (
    <>
      <hr style={styles} />
      <footer role="contentinfo">
        <nav aria-label={t("footerMenus")}>
          <Grid style={{ paddingBottom: 20 }} container>
            {isUserAuthenticated ? (
              <Grid item xs={12} md={3} lg={3}>
                <Box m={1} p={1} borderColor="primary.main">
                  <Typography variant="h2">
                    {!languageCode ? "INFORMATION" : t("Footer.Information")}
                  </Typography>
                  <ul style={{ paddingLeft: 0 }}>
                    {isUserAuthenticated ? (
                      <li style={{ listStyleType: "none" }}>
                        <Link
                          to={`/legalloggedin/corporateinformation?locale=${languageCode ?? "en-GB"
                            }`}
                          id="corporateInformationLink"
                          //target="_blank"
                          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                          variant="caption"
                          color="inherit"
                        >
                          <Typography variant="caption">
                            {t("Footer.CorporateInformation")}
                          </Typography>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    <li style={{ listStyleType: "none" }}>
                      <Link
                        to={`/legalanonymous/termsofuse?locale=${languageCode ?? "en-GB"
                          }`}
                        id="termsofuseLink"
                        //target="_blank"
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                        rel="noopener noreferrer"
                        variant="caption"
                        color="inherit"
                      >
                        <Typography variant="caption">
                          {!languageCode
                            ? "Terms of Use"
                            : t("Footer.TermsofUse")}
                        </Typography>
                      </Link>
                    </li>
                    <li style={{ listStyleType: "none" }}>
                      <MuiLink
                        href={privacyPolicyLink()}
                        id="privacyPolicyLink"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                        variant="caption"
                      >
                        <Typography variant="caption">
                          {" "}
                          {!languageCode
                            ? "Privacy Policy"
                            : t("Footer.PrivacyPolicy")}
                        </Typography>

                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                      </MuiLink>
                    </li>
                    <li style={{ listStyleType: "none" }}>
                      <MuiLink
                        href={cookiePolicyLink()}
                        id="cookiepolicyLink"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                        variant="caption"
                      >
                        <Typography variant="caption">
                          {" "}
                          {!languageCode
                            ? "Cookie Policy"
                            : t("Footer.CookiePolicy")}
                        </Typography>
                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                      </MuiLink>
                    </li>
                  </ul>
                </Box>
              </Grid>
            ) : ""}
            {isUserAuthenticated ? (
              <Grid item xs={12} md={1} lg={1}>
                {!isSmXs && (
                  <Box
                    width={10}
                    m={1}
                    p={1}
                    borderRight={1}
                    borderColor="primary.main"
                  >
                    <div style={{ width: 1, height: 120 }}></div>
                  </Box>
                )}
              </Grid>
            ) : ""}
            <Grid item xs={12} md={3} lg={3}>
              <Box m={1} p={1} borderColor="primary.main">
                <Typography variant="h2">
                  {!languageCode ? "QUICK LINKS" : t("Footer.QuickLinks")}
                </Typography>
                <ul style={{ paddingLeft: 0 }}>
                  {isAdminOrEmployee ? (
                    <li style={{ listStyleType: "none" }}>
                      <Link
                        to={`/companyprofile/contactus?locale=${languageCode ?? "en-GB"
                          }`}
                        id="contactUsLink"
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                        color="inherit"
                        variant="caption"
                        //target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="caption">
                          {!languageCode ? "Contact Us" : t("Footer.ContactUs")}
                        </Typography>
                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {" "}
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                      </Link>
                    </li>
                  ) : (
                    <li style={{ listStyleType: "none" }}>
                      <Link
                        to={`/legalanonymous/ithelp?locale=${languageCode ?? "en-GB"
                          }`}
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                        id="contactUsLink"
                        color="inherit"
                        variant="caption"
                        //target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="caption">
                          {!languageCode ? "Contact Us" : t("Footer.ContactUs")}
                        </Typography>
                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                      </Link>
                    </li>
                  )}

                  {
                    isUserAuthenticated ? (
                      <li style={{ listStyleType: "none" }}>
                        <MuiLink
                          href={userGuideURL}
                          id="userguideLink"
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                          variant="caption"
                        >
                          <Typography variant="caption">
                            {" "}
                            {!languageCode ? "FAQ" : t("Footer.FAQ")}
                          </Typography>

                          <Box component="span" className="visuallyHidden">
                            <Typography variant="caption">
                              {!languageCode
                                ? ",opens new window"
                                : t("Footer.OpenNewWindow")}
                            </Typography>
                          </Box>
                        </MuiLink>
                      </li>
                    ) : (
                      ""
                    )
                  }
                  {isUserAuthenticated ? (
                    ""
                  ) : ( 
                      <li style={{ listStyleType: "none" }}>
                        <Link
                          to="/#"
                          onClick={handleLogin}
                          id="ehiLoginLink"
                          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                          color="inherit"
                          variant="caption"
                          rel="noopener noreferrer"
                        >
                          <Typography variant="caption">
                            {!languageCode ? "EHI Login" : t("Footer.EHILogin")}
                          </Typography>
                        </Link>
                      </li>
                  )}
                </ul>
              </Box>
            </Grid>
            {userNotAdminAndHasCompanyLink ? (
              <Grid item xs={12} md={1} lg={1}>
                {!isSmXs && (
                  <Box
                    width={10}
                    m={1}
                    p={1}
                    borderRight={1}
                    borderColor="primary.main"
                  >
                    <div style={{ width: 1, height: 120 }}></div>
                  </Box>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} md={1} lg={1}>
                {!isSmXs && (
                  <Box width={10} m={1} p={1} borderColor="primary.main">
                    <div style={{ width: 1, height: 120 }}></div>
                  </Box>
                )}
              </Grid>
            )}
            {userNotAdminAndHasCompanyLink ? (
              <Grid item xs={12} md={4} lg={4}>
                <Box m={1} p={1} borderColor="primary.main">
                  <Typography variant="h2">
                    {!languageCode
                      ? "YOUR COMPANY LINKS"
                      : t("Footer.YourCompanyLinks")}
                  </Typography>
                      <ul style={{ paddingLeft: 0}}>
                      {companyLinks?.map((p, index) => {
                        return (
                              <li style={{ listStyleType: "none" }} key={p.linkDescription}>
                              <MuiLink
                                color="inherit"
                                variant="caption"
                                target="_blank"
                                id={"companyLink" + index}
                                rel="noopener noreferrer"
                                href={
                                  "https://" +
                                  p.linkURL
                                    .replace("https://", "")
                                    .replace("http://", "")
                                }
                              >
                                <Typography variant="caption">
                                  {p.linkDescription}{" "}
                                </Typography>
                                <Box
                                  component="span"
                                  className="visuallyHidden"
                                >
                                  <Typography variant="caption">
                                    {!languageCode
                                      ? ",opens new window"
                                      : t("Footer.OpenNewWindow")}
                                  </Typography>
                                </Box>
                              </MuiLink>
                            </li>
                        );
                      })}
                      </ul>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} md={4} lg={4}>
                <Box m={1} p={1} borderColor="primary.main">
                  <div style={{ width: 1, height: 120 }}></div>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} md={4} lg={4}></Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography style={{ fontSize: "11px" }} align="center">
                ©{new Date().getFullYear()} Enterprise Holdings, Inc. &nbsp;&nbsp;{" "}
                {!languageCode ? "Version" : t("Footer.Version")}{" "}
                {configParams.MAJOR_RELEASE + "." + configParams.BUILD_NUMBER}{" "}
              </Typography>
            </Grid>
            <Grid style={{ height: 10 }} item xs={12} md={4} lg={4}></Grid>
          </Grid>
        </nav>
      </footer>
    </>
  );
}

export default Footer;
